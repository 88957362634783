<template>
  <base-main-container>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="68">
        AI is preparing your resource
      </v-progress-circular>
    </v-overlay>

    <template v-slot:centered>
      <v-form>
        <v-card class="my-card rounded-lg" elevation="0">
          <v-card-title>ClassClown AI: Humorous Classroom Joke Generator </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="topic"
              class="mt-4 ml-2"
              label="Topic/Subject"
              outlined
              :rules="[rules.required]"
            />
            
            <v-select
              class="ml-2"
              append-icon="mdi-filter"
              v-model="grade"
              :items="['K','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11','12']"
              label="Suitable for Grade level"
              outlined
              clearable
            />

          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="mr-2" outlined link to="newresource">
              <v-icon class="mr-2"> mdi-backspace</v-icon> Cancel
            </v-btn>
 
            <v-btn :disabled="!topic" color="#212121" @click="sendRequest()">
              <h3 v-if="!topic">Generate please</h3>
              <h3 v-else style="color: white">Generate please</h3>
            </v-btn>
          </v-card-actions>
           <v-card-text  v-if="response" class="pa-5">
            <v-divider></v-divider>
             <div 
            
             v-html="response"
             class="custom-font mt-10"
             ></div>
            
          </v-card-text>
        </v-card>
      </v-form>
    </template>
    <v-snackbar v-model="showSnackbar" :timeout="6000" color="warning" top>
      {{ snackbarMessege }}
    </v-snackbar>
  </base-main-container>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";



export default {
  data() {
    return {
      what: "Joke",
      topic: null,
      grade: null,
     response: null,
      isLoading: false,
      showSnackbar: false,
      snackbarMessege: "",
      rules: {
        required: (value) => !!value || "Required.",
        max: (v) => v <= 50 || "Max 50 items",
             },
    };
  },
//   computed: {
//     premium() {
//       return this.$store.state.subscription
//         ? this.$store.state.subscription.status == "active" ||
//             this.$store.state.subscription.status == "active school" ||
//             this.$store.state.subscription.status == "trialing"
//         : false;
//     },
//   },

  methods: {
    async sendRequest() {
      this.isLoading = true;

   
      this.grade = this.grade
        ? "Yes, this should be suitable for grade level: " + this.grade
        : "no specific grade level";
      
     
      let promt = [
          {
            role: "system",
            content: "You are an excellent educator and teacher.",
          },
          {
            role: "user",
            content:
              "I am a teachers, can you give me a joke I can use in my class",
          },
          {
            role: "assistant",
            content:
              "Sure, What is the topic you will be teaching",
          },
          { role: "user", content: this.topic }, 
          {
            role: "assistant",
            content:
              "Thank you. what is the grade level of your students?",
          },
          { role: "user", content: this.grade },
          {
            role: "assistant",
            content:
              "Thank you, I have all the details that I need. Do you have any other details before I generate the joke for you?",
          },
          {
            role: "user",
            content: "No, that is all, please give me a joke to my class fun and engaging",
          },
        ]
      

      const functions = getFunctions();

    try {
        const functionRef = httpsCallable(functions, "callopenaiForResponse");
        const { data } = await functionRef({
          promt: promt,
        });

        this.response = data.result;
        this.isLoading = false;
       
       } catch (error) {
        this.isLoading = false;
        this.response = null;
       
        this.showSnackbar = true;
        this.snackbarMessege = "The system is overloaded, please try again";
      }
    },

    clearSelections() {
      this.topic = null;
      this.grade = null;

    },
  },
};
</script>

<style scoped>
.custom-font {
  font-family: 'Arial', sans-serif; /* Change 'Arial' to the desired font family */
  font-size: 16px;
  font-weight: normal;
  color: black;
  letter-spacing: normal;
   padding: 12px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
           background:#e5e4e4;
          
            border-radius: 10px;
            margin-bottom: 22.5px;
          
  /* Other font properties such as font-size, font-weight, etc. can also be applied here */
}
@media (min-width: 600px) {
  .my-card {
    width: 300px;
  }
}
@media (min-width: 960px) {
  .my-card {
    width: 500px;
  }
}
@media (min-width: 1264px) {
  .my-card {
    width: 800px;
  }
}
</style>




